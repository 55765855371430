import React from "react"
import { Fade } from "react-awesome-reveal"
import styled from "styled-components"
import coworkImage1 from "../assets/images/03_COWORK_carousel/cowork_1.jpg"
import coworkImage2 from "../assets/images/03_COWORK_carousel/cowork_2.jpg"
import coworkImage4 from "../assets/images/03_COWORK_carousel/cowork_4.jpg"
import coworkImage5 from "../assets/images/03_COWORK_carousel/cowork_5.jpg"
import coworkImage6 from "../assets/images/03_COWORK_carousel/cowork_6.jpg"
import coworkImage7 from "../assets/images/03_COWORK_carousel/cowork_7.jpg"
import coworkImage8 from "../assets/images/03_COWORK_carousel/cowork_8.jpg"
import coworkImage9 from "../assets/images/03_COWORK_carousel/cowork_9.jpg"
import SEO from "../components/SEO"

import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import "../styles/sliderStyler.css"

const PageStyles = styled.div`
  /* scroll-snap-type: y mandatory;
  overflow-y: scroll; */
  height: calc(100vh - 150px);
`

const ImageStyles = styled.div`
  /* scroll-snap-align: start; */
  background-size: cover;
  height: calc(100vh - 150px);
`

const BodyStyles = styled.div`
  /* scroll-snap-align: start; */
  display: flex;
  min-height: calc(100vh - 150px);
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 130px;

  @media (max-width: 900px) {
    padding-left: 90px;
    padding-right: 40px;
    padding-bottom: 120px;
    padding-top: 45px;
    min-height: 0;
  }

  h1 {
    margin-left: -65px;
    margin-bottom: 10px;
  }

  p {
    margin-bottom: 20px;
  }

  article {
    columns: 2;
    column-width: 330px;
    column-gap: 30px;
  }
`

function CoworkPage() {
  const [pause, setPause] = React.useState(false)
  const timer = React.useRef()
  const [sliderRef, slider] = useKeenSlider({
    loop: true,
    duration: 1000,
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
  })

  React.useEffect(() => {
    sliderRef.current.addEventListener("mouseover", () => {
      setPause(true)
    })
    sliderRef.current.addEventListener("mouseout", () => {
      setPause(false)
    })
  }, [sliderRef])

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider) {
        slider.next()
      }
    }, 2000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  return (
    <>
      <SEO title="Cowork" image={coworkImage1} />
      <Fade triggerOnce>
        <PageStyles>
          <div ref={sliderRef} className="keen-slider">
            <div
              className="keen-slider__slide number-slide1"
              style={{
                backgroundImage: `url(${coworkImage1})`,
              }}
            ></div>
            <div
              className="keen-slider__slide number-slide2"
              style={{ backgroundImage: `url(${coworkImage2})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide3"
              style={{ backgroundImage: `url(${coworkImage4})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide4"
              style={{ backgroundImage: `url(${coworkImage5})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide5"
              style={{ backgroundImage: `url(${coworkImage6})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide6"
              style={{ backgroundImage: `url(${coworkImage7})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide6"
              style={{ backgroundImage: `url(${coworkImage8})` }}
            ></div>
            <div
              className="keen-slider__slide number-slide6"
              style={{ backgroundImage: `url(${coworkImage9})` }}
            ></div>
          </div>
          <BodyStyles>
            <div>
              <h1>COWORK</h1>

              <article>
                <p>
                  Shout out to the new wave of digital drifters, a nomadic tribe
                  who have emacipated themselves from 9 to 5 cubicle living to
                  priortise living life on their own terms. We love these
                  people!
                </p>
                <p>
                  Our cowork was designed to offer a peaceful space to focus and
                  get work done. Full of light, with views of coconut trees and
                  breaking waves. You can take a 10 minute break and walk 15
                  metres into the warm Indian Ocean, or better, break for an
                  hour and go for a quick surf. That is what this lifestyle is
                  all about. Your time is yours, and the best parts of the day
                  don’t have to be spent at a desk.
                </p>
                <p>
                  Hang out in our loungy common areas and order a coffee, it’s
                  the perfect place to meet people, make connections, network
                  and discover new business opportunities.
                </p>
                <p>
                  We have the best high speed fibre system on the island, it is
                  approximately 100 MBPS download and 40 MBPS upload speeds. We
                  also have penty of charging points for all your tech.
                </p>
                <p>
                  The cowork offers indoor and outdoor desk space, as well as
                  sofas so you can choose how you want to work.
                </p>
              </article>
            </div>
          </BodyStyles>
        </PageStyles>
      </Fade>
    </>
  )
}

export default CoworkPage
